import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueClipboard  from 'vue-clipboard2';
import '@vant/touch-emulator';
import './filters/global-filters';

Vue.config.productionTip = false;
Vue.use(VueClipboard);

new Vue({
  render: h => h(App),
  router
}).$mount('#app');
