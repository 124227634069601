import Vue from 'vue';
import Router from 'vue-router'

import Layout from './Layout.vue';
// import Evaluation from './views/evaluation.vue';
// import EvaluationResult from './views/evaluation-result.vue';
import AccountList from './views/account-list.vue';
import QuickAccountList from './views/quick-account-list.vue';
import AccountInfo from './views/account-info.vue';
import ConsultService from './views/consult-service.vue';
import ArticleList from './views/article-list.vue';
import ArticleDetail from './views/article-detail.vue';

const routes = [
  {
    path: '',
    component: Layout,
    redirect: { name: 'quickAccount' },
    name: 'evaluation',
    children: [
      // 快速浏览账号
      { name: 'quickAccount', path: '/quick-account-list', component: QuickAccountList, meta: { activeName: 'quickAccount' } },
      // 估价首页
      // { name: 'evaluationIndex', path: '/evaluation', component: Evaluation, meta: { activeName: 'evaluation' } },
      // 估价结果页
      // { name: 'evaluationResult', path: '/evaluation-result', component: EvaluationResult, meta: { activeName: 'evaluation' } },
      // 账号列表
      { name: 'trade', path: '/account-list', component: AccountList, meta: { activeName: 'trade' } },
      // 账号信息
      { name: 'accountInfo', path: '/account-info/:id', component: AccountInfo, props: (route) => ({ id: route.params.id }) },
      // 咨询页
      { name: 'service', path: '/consult-service', component: ConsultService, meta: { activeName: 'service' } },

      // 攻略列表
      { name: 'articleList', path: '/article-list', component: ArticleList, meta: { activeName: 'articleList' }},
      // 攻略详情
      { name: 'articleDetail', path: '/article-detail/:id', component: ArticleDetail, props: (route) => ({ id: route.params.id }) },
    ]
  },
];

Vue.use(Router);
export default new Router({
  mode: 'history',
  routes
});
