import { render, staticRenderFns } from "./catastrophe.vue?vue&type=template&id=98e0b4ac&scoped=true&"
import script from "./catastrophe.vue?vue&type=script&lang=js&"
export * from "./catastrophe.vue?vue&type=script&lang=js&"
import style0 from "./catastrophe.vue?vue&type=style&index=0&id=98e0b4ac&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98e0b4ac",
  null
  
)

export default component.exports