<template>
  <div>
    <van-form class="custom-form" @submit="onQuery">
      <p class="header">以武器神装为例，等级必须是20的倍数，如20 40 60</p>
      <van-field
        v-model="currentLevel"
        type="number"
        name="起始等级"
        label="起始等级"
        placeholder="起始等级"
        :rules="currentLevelRule"
      />
      <van-field
        v-model="targetLevel"
        type="number"
        name="目标等级"
        label="目标等级"
        placeholder="目标等级"
        :rules="targetLevelRule"
      />
      <template v-if="jade">
        <p class="result">升级所需强化石数量	：<span class="height-light">{{ jade }}</span></p>
      </template>
      <div class="custom-btn-wrap">
        <van-button round block type="info" native-type="submit">查询</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
  import { Form, Field, Button } from 'vant';

  const DATA_ARRAY = [145, 195, 270, 370, 495, 645, 820, 1020, 1245, 1495, 1770, 2070, 2395, 2745, 3210, 4720, 6370, 8600, 11610, 15670, 21150, 28550, 38540, 52030];

  export default {
    name: 'Equip',
    components: {
      VanForm: Form,
      VanField: Field,
      VanButton: Button,
    },
    data() {
      return {
        currentLevel: '',
        targetLevel: '',
        currentLevelRule: [
          { required: true, message: '请填写起始等级' },
          { validator: (value) => !(value % 20), message: '起始等级必须是20的倍数' },
          { validator: (value) => value >= 20 && value < 500, message: '起始等级必须大于等于20并且小于500' },
        ],
        targetLevelRule: [
          { required: true, message: '请填写目标等级' },
          { validator: (value) => !(value % 20), message: '目标等级必须是20的倍数' },
          { validator: (value) => value > 20 && value <= 500, message: '目标等级必须大于20并且小于等于500' },
          { validator: (value) => Number(value) > Number(this.currentLevel), message: '目标等级必须大于起始等级' },
        ],
        jade: 0,
      }
    },
    methods: {
      onQuery() {
        let startIndex = parseInt(this.currentLevel / 20) - 1;
        let endIndex = parseInt(this.targetLevel / 20) - 1;
        let jade = 0;
        for (let i = startIndex; i < endIndex; i++) {
          jade += DATA_ARRAY[i];
        }
        this.jade = jade;
      },
    }
  }
</script>

<style scoped lang="scss">
  .header {
    margin: 10px;
    font-size: 14px;
    color: rgb(69, 90, 100, 0.6);
    text-align: left;
  }
  .result {
    margin: 10px;
    font-size: 14px;
    color: #333;
    .height-light {
      color: #FF5260;
    }
  }
  .custom-form {
    margin-bottom: 30px;
  }
  .custom-btn-wrap {
    margin: 16px;
  }
</style>
