<template>
  <div>
    <keep-alive exclude="ArticleDetail,AccountInfo,Evaluation">
      <router-view id="main-container"></router-view>
    </keep-alive>
    <van-tabbar v-if="active" :safe-area-inset-bottom="true" active-color="#e8b77e" inactive-color="#6a6a7b" v-model="active">
      <!--<van-tabbar-item name="evaluation" icon="gold-coin-o">估价器</van-tabbar-item>-->
      <van-tabbar-item name="quickAccount" icon="apps-o">快速浏览</van-tabbar-item>
      <van-tabbar-item name="trade" icon="cart-o">展示号</van-tabbar-item>
      <van-tabbar-item name="articleList" icon="fire-o">百宝箱</van-tabbar-item>
      <van-tabbar-item name="service" icon="diamond-o">1对1攻略咨询</van-tabbar-item>
    </van-tabbar>
    <!--<div class="strategy-btn" @click="showService = true" v-if="active === 'evaluation'">我要<br/>咨询</div>-->
    <van-popup round :style="{ width: 'calc(100% - 40px)', padding: '20px' }" v-model="showService" closeable>
      <consult />
    </van-popup>
  </div>
</template>

<script>
  import { Tabbar, TabbarItem, Toast, Popup, Dialog } from 'vant';
  import Consult from "./components/consult";
  import { getSettings } from './api/index';

  Toast.setDefaultOptions('loading', { forbidClick: true, duration: 0 });
  Toast.allowMultiple();

  export default {
    name: 'Layout',
    components: {
      Consult,
      VanTabbar: Tabbar,
      VanTabbarItem: TabbarItem,
      VanPopup: Popup,
    },
    data() {
      return {
        showService: false,
        active: this.activeName || 'quickAccount',
      };
    },
    async created() {
      window.wechat = localStorage.getItem('Wechat') || '182787418';
      window.wechatImg = localStorage.getItem('WechatImg') || 'http://cdn.mumujia.shop/evaluation/qrcode_ca039e88_cd9bf5c412';
      window.dealCost = localStorage.getItem('DealCost') ? JSON.parse(localStorage.getItem('DealCost')) : {"text": "手xù100", "color": "#ee0c24"};

      getSettings(['HomeModal', 'Wechat', 'DealCost']).then(res => {
        if (!res || !res.length) return false;
        for (let config of res) {
          if (config.code === 'HomeModal' && config.text) {
            let oldMessage = localStorage.getItem('HomeModal');
            if (oldMessage !== config.text) {
              Dialog.alert({
                message: config.text,
                theme: 'round-button',
                confirmButtonColor: 'linear-gradient(to left, #e8b77e, #f7d8b0)'
              });
              localStorage.setItem('HomeModal', config.text);
            }
          }
          if (config.code === 'Wechat') {
            if (config.text) {
              localStorage.setItem('Wechat', config.text);
              window.wechat = config.text;
              document.title = `二木大仙（微信${config.text}）展示号`;
            }
            if (config.pictures && config.pictures.length) {
              localStorage.setItem('wechatImg', config.pictures[0]);
              window.wechatImg = config.pictures[0];
            }
          }
          if (config.code === 'DealCost') {
            try {
              const data = JSON.parse(config.text);
              localStorage.setItem('DealCost', config.text);
              window.dealCost = data;
            } catch (e) {
              // 避免json解析错误
              console.error(e);
            }
          }
        }
      })
    },
    watch: {
      '$route': {
        immediate: true,
        handler() {
          if (this.$route.meta && this.$route.meta.activeName) {
            this.active = this.$route.meta.activeName
          } else {
            this.active = '';
          }
        }
      },
      'active': {
        handler(newVal) {
          if (this.$route.name === this.active || !newVal) return false;
          this.$router.push({ name: this.active });
        }
      }
    }
  }
</script>

<style>
  * {
    box-sizing: border-box;
  }
  body {
    border: 0;
    padding: 0;
    margin: 0;
    background: #f1f1f1;
    color: #333;
  }
  #main-container {
    margin-bottom: 60px;
  }
  .strategy-btn {
    display: inline-block;
    background: #6a6a7b;
    border-radius: 50%;
    width: 42px;
    height: 42px;
    font-size: 12px;
    text-align: center;
    line-height: 16px;
    padding: 5px 0;
    color: #FFF;
    position: fixed;
    right: 18px;
    bottom: 70px;
    animation: shake 4s linear;
    animation-iteration-count: infinite;
  }
  @keyframes shake { /* 水平抖动 */
    /*75% { transform: translate3d(0, 0, 0); }*/
    /*77.5%, 97.5% { transform: translate3d(-1px, 0, 0); }*/
    /*80%, 95% { transform: translate3d(+2px, 0, 0); }*/
    /*82.5%, 92.5% { transform: translate3d(-4px, 0, 0); }*/
    /*85%, 90% { transform: translate3d(+4px, 0, 0); }*/
    /*87.5% { transform: translate3d(-4px, 0, 0); }*/

    10% { transform: scale(0.9) }

    20% { transform: scale(1.1) }

    30% { transform: scale(0.9) }

    40% { transform: scale(1.2) }

    50% { transform: scale(1) }
  }
</style>
