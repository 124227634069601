<template>
  <div>
    <h1>长按扫码添加「二木大仙」</h1>
    <img class="qrcode" :src="wechatImg">
    <p class="tips">微信号: {{ wechat }}</p>
    <h3>主营业务：</h3>
    <p>1对1攻略咨询</p>
    <p>展示号</p>
    <p>代练妖王地煞日常活动</p>
    <p>或其他业务均可咨询</p>
  </div>
</template>

<script>
  export default {
    name: 'Consult',
    data() {
      return {
        wechat: window.wechat,
        wechatImg: window.wechatImg,
      };
    },
  }
</script>

<style scoped lang="scss">
  h1 {
    margin: 15px 0;
    font-size: 22px;
    text-align: center;
  }
  .qrcode {
    display: block;
    width: 150px;
    margin: 0 auto;
  }
  .tips {
    color: #888;
    font-size: 14px;
    text-align: center;
    margin-bottom: 25px;
  }
  h3, p {
    margin: 10px 0;
  }
  p {
    color: #333;
  }
</style>
