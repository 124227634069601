<template>
  <div>
    <van-form class="custom-form" @submit="onQuery">
      <van-field name="radioGroup" label="伙伴品质">
        <template #input>
          <van-radio-group v-model="activeQuality" direction="horizontal">
            <van-radio v-for="(quality, index) of qualities" :key="index" :name="index">{{ quality }}</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div class="custom-slides-wrap">
        <vue-slider v-model="levelValue" v-bind="sliderOptions"></vue-slider>
      </div>
      <template v-if="result.fragment">
        <p class="result">升级所需碎片数量	：<span class="height-light">{{ result.fragment }}</span></p>
        <p class="result" v-if="result.goldBox">需要金盒子数量	：<span class="height-light">{{ result.goldBox }}</span></p>
        <p class="result" v-if="result.thread">需要帖子数量	：<span class="height-light">{{ result.thread }}</span></p>
      </template>
      <div class="custom-btn-wrap">
        <van-button round block type="info" native-type="submit">查询</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
  import { Form, Field, Button, RadioGroup, Radio } from 'vant';
  import VueSlider from 'vue-slider-component';
  import 'vue-slider-component/theme/default.css';

  const QUALITIES = ['金色', '红色', '橙色', '紫色', '蓝色'];
  const DATA_ARRAY = [[[20,1,0],[20,1,60],[40,2,120],[60,3,180],[80,4,240],[100,5,300]],[[20,0,0],[40,0,0],[80,0,0],[160,0,0],[300,0,0],[600,0,0]],[[20,0,0],[20,0,0],[40,0,0],[80,0,0],[200,0,0],[300,0,0]],[[20,0,0],[20,0,0],[40,0,0],[100,0,0],[200,0,0],[300,0,0]],[[20,0,0],[20,0,0],[40,0,0],[100,0,0],[200,0,0],[300,0,0]]];

  export default {
    name: 'Partner',
    components: {
      VanForm: Form,
      VanField: Field,
      VanButton: Button,
      VanRadioGroup: RadioGroup,
      VanRadio: Radio,
      VueSlider,
    },
    data() {
      return {
        qualities: QUALITIES,
        activeQuality: 0,
        levelValue: [ '0', '1' ],
        sliderOptions: {
          data: {
            '0': '激活',
            '1': '0级',
            '2': '1级',
            '3': '2级',
            '4': '3级',
            '5': '4级',
            '6': '5级',
          },
          marks: {
            '0': '激活',
            '1': '0级',
            '2': '1级',
            '3': '2级',
            '4': '3级',
            '5': '4级',
            '6': '5级',
          },
          minRange: 1,
          enableCross: false,
        },
        result: {
          fragment: 0,
          goldBox: 0,
          thread: 0,
        },
      }
    },
    methods: {
      onQuery() {
        let startIndex = Number(this.levelValue[0]);
        let endIndex = Number(this.levelValue[1]);
        const qualityArray = DATA_ARRAY[this.activeQuality];

        let fragment = 0;
        let goldBox = 0;
        let thread = 0;
        for (let i = startIndex; i < endIndex; i++) {
          fragment += qualityArray[i][0];
          goldBox += qualityArray[i][1];
          thread += qualityArray[i][2];
        }

        Object.assign(this.result, {
          fragment,
          goldBox,
          thread
        })
      },
    }
  }
</script>

<style scoped lang="scss">
  .custom-slides-wrap {
    padding: 30px 30px 40px 20px;
    background: #FFF;
  }
  ::v-deep .van-radio {
    margin-bottom: 10px;
  }
  .result {
    margin: 10px;
    font-size: 14px;
    color: #333;
    .height-light {
      color: #FF5260;
    }
  }
  .custom-form {
    margin-bottom: 30px;
  }
  .custom-btn-wrap {
    margin: 16px;
  }
</style>
