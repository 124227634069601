<template>
  <div>
    <van-form class="custom-form" @submit="onQuery">
      <van-field name="radioGroup" label="宝石种类">
        <template #input>
          <van-radio-group v-model="activeType" direction="horizontal">
            <van-radio v-for="mine of mineTypes" :key="mine.type" :name="mine.type">{{ mine.label }}</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-model="currentLevel"
        type="number"
        name="当前等级"
        label="当前等级"
        placeholder="当前等级"
        :rules="currentLevelRule"
      />
      <van-field
        v-model="targetLevel"
        type="number"
        name="目标等级"
        label="目标等级"
        placeholder="目标等级"
        :rules="targetLevelRule"
      />
      <template v-if="gems">
        <p class="result">需要宝石数量	：<span class="height-light">{{ gems }}</span></p>
        <p class="result">大约需要赤铁矿数量：<span class="height-light">{{ mines }}</span></p>
      </template>
      <div class="custom-btn-wrap">
        <van-button round block type="info" native-type="submit">查询</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
  import { Form, Field, Button, RadioGroup, Radio } from 'vant';

  const MINE_TYPES = [
    {
      type: 1,
      label: '星辉石',
      baseIron: 160,
      baseJade: 2,
    },
    {
      type: 2,
      label: '舍利子',
      baseIron: 320,
      baseJade: 2,
    },
    {
      type: 3,
      label: '黑宝石',
      baseIron: 320,
      baseJade: 2,
    },
  ];
  const DATA_ARRAY = [[2,160,320,320],[4,320,640,640],[8,640,1280,1280],[16,1280,2560,2560],[32,2560,5120,5120],[64,5120,10240,10240],[128,10240,20480,20480],[256,20480,40960,40960],[512,40960,81920,81920],[1024,81920,163840,163840],[2048,163840,327680,327680],[4096,327680,655360,655360],[8192,655360,1310720,1310720],[16384,1310720,2621440,2621440],[32768,2621440,5242880,5242880],[65536,5242880,10485760,10485760],[131072,10485760,20971520,20971520],[262144,20971520,41943040,41943040],[524288,41943040,83886080,83886080]];

  export default {
    name: 'Catastrophe',
    components: {
      VanForm: Form,
      VanField: Field,
      VanButton: Button,
      VanRadioGroup: RadioGroup,
      VanRadio: Radio,
    },
    data() {
      return {
        mineTypes: MINE_TYPES,
        activeType: 1,
        currentLevel: '',
        targetLevel: '',
        currentLevelRule: [
          { required: true, message: '请填写当前等级' },
          { validator: (value) => value >= 1 && value < 20, message: '当前等级必须大于等于1并且小于20' },
        ],
        targetLevelRule: [
          { required: true, message: '请填写目标等级' },
          { validator: (value) => value > 1 && value <= 20, message: '目标等级必须大于1并且小于等于20' },
          { validator: (value) => Number(value) > Number(this.currentLevel), message: '目标等级必须大于当前等级' },
        ],
        gems: 0,
        mines: 0,
      }
    },
    methods: {
      onQuery() {
        let startIndex = this.currentLevel - 1;
        let endIndex = this.targetLevel - 1;
        this.gems = 0;
        this.mines = 0;
        for (let i = startIndex; i < endIndex; i++) {
          this.gems += DATA_ARRAY[i][0];
          this.mines += DATA_ARRAY[i][this.activeType];
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  .result {
    margin: 10px;
    font-size: 14px;
    color: #333;
    .height-light {
      color: #FF5260;
    }
  }
  .custom-form {
    margin-bottom: 30px;
  }
  .custom-btn-wrap {
    margin: 16px;
  }
  ::v-deep .van-cell__title {
    width: 60px;
  }
</style>
