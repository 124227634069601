<template>
  <div>
    <van-form class="custom-form" @submit="onQueryExp">
      <p class="header">从开始等级到目标等级需要多少经验</p>
      <van-field
        v-model="form1.currentLevel"
        type="number"
        name="开始等级"
        label="开始等级"
        placeholder="开始等级"
        :rules="currentLevelRule"
      />
      <van-field
        v-model="form1.targetLevel"
        type="number"
        name="目标等级"
        label="目标等级"
        placeholder="目标等级"
        :rules="targetLevelRule"
      />
      <p class="result" v-if="form1.needExp">升级所需经验为：<span class="height-light">{{form1.needExp}}亿</span></p>
      <div class="custom-btn-wrap">
        <van-button round block type="info" native-type="submit">查询</van-button>
      </div>
    </van-form>

    <van-form @submit="onQueryTargetLevel">
      <p class="header">我存的经验能升到到多少级</p>
      <van-field
        v-model="form2.currentLevel"
        type="number"
        name="开始等级"
        label="开始等级"
        placeholder="开始等级"
        :rules="currentLevelRule"
      />
      <van-field
        v-model="form2.currentExp"
        type="number"
        name="现存经验（亿）"
        label="现存经验"
        placeholder="现存经验（亿）"
        :rules="expRule"
      />
      <p class="result" v-if="form2.targetLevel">可达目标等级为：<span class="height-light">{{form2.targetLevel}}级</span></p>
      <div class="custom-btn-wrap">
        <van-button round block type="info" native-type="submit">查询</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
  import { Form, Field, Button } from 'vant';


  const EXP_ARRAY = [128,134,140,146,153,159,166,174,182,190,198,207,216,226,236,246,257,269,281,293,306,320,334,349,365,381,398,416,434,454,474,495,517,541,565,590,617,644,673,703,735,768,802,838,876,915,956,999,1044,1091,1140,1191,1245,1301,1359,1420,1484,1551,1621,1694,1770,1850,1933,2021,2112,2207,2307,2411,2520,2633,2752,2877,3007,3143,3285,3433,3589,3751,3921,4098,4284,4478,4680,4893,5114,5346,5589,5842,6107,6384,6674,6977,7293,7625,7971,8333,8712,9108,9522,9955,10408,10581,10757,10936,11118,11303,11491,11682,11875,12072,12272,12476,12682,12892,13106,13322,13543,13766,13994,14225];

  export default {
    name: 'Exp',
    components: {
      VanForm: Form,
      VanField: Field,
      VanButton: Button,
    },
    data() {
      return {
        form1: {
          currentLevel: '',
          targetLevel: '',
          needExp: 0,
        },
        form2: {
          currentLevel: '',
          targetLevel: '',
          currentExp: '',
        },
        currentLevelRule: [
          { required: true, message: '请填写开始等级' },
          { validator: (value) => value >= 400 && value < 520, message: '开始等级必须大于等于400并且小于520' },
        ],
        targetLevelRule: [
          { required: true, message: '请填写目标等级' },
          { validator: (value) => value > 400 && value <= 520, message: '目标等级必须大于400并且小于等于520' },
          { validator: (value) => Number(value) > Number(this.form1.currentLevel), message: '目标等级必须大于开始等级' },
        ],
        expRule: [
          { required: true, message: '请填写现存经验' },
        ]
      }
    },
    methods: {
      onQueryExp() {
        let startIndex = this.form1.currentLevel - 400;
        let endIndex = this.form1.targetLevel - 400;
        let needExp = 0;
        for (let i = startIndex; i < endIndex; i++) {
          needExp += EXP_ARRAY[i];
        }
        this.form1.needExp = needExp;
      },
      onQueryTargetLevel() {
        let startIndex = this.form2.currentLevel - 400;
        let currentExp = this.form2.currentExp;
        let i = startIndex;
        for ( ; currentExp > 0; i++) {
          currentExp -= EXP_ARRAY[i];
        }
        this.form2.targetLevel = i + 399;
      }
    }
  }
</script>

<style scoped lang="scss">
  .header {
    margin: 10px;
    font-size: 14px;
    color: rgb(69, 90, 100, 0.6);
    text-align: center;
  }
  .result {
    margin: 10px;
    font-size: 14px;
    color: #333;
    .height-light {
      color: #FF5260;
    }
  }
  .custom-form {
    margin-bottom: 30px;
  }
  .custom-btn-wrap {
    margin: 16px;
  }
</style>
