<template>
  <div class="container">
    <van-notice-bar left-icon="volume-o" v-if="noticeText" :text=noticeText />
    <div class="card">
      <consult />
    </div>
    <img class="ad-picture" v-for="(picture, index) of adsPictures" :src="picture" :key="index" />
  </div>
</template>

<script>
  import { NoticeBar } from 'vant';
  import { getSettings } from './../api/index';
  import Consult from "../components/consult";

  export default {
    name: 'ConsultService',
    components: { Consult, VanNoticeBar: NoticeBar },
    data() {
      return {
        adsPictures: [],
        noticeText: '',
      }
    },
    created() {
      getSettings(['ServiceText', 'ServicePictures']).then(res => {
        if (!res || !res.length) return false;
        for (const config of res) {
          if (config.code === 'ServiceText') {
            this.noticeText = res.text;
          } else if (config.code === 'ServicePictures') {
            this.adsPictures = res.pictures;
          }
        }
      })
    }
  }
</script>

<style scoped lang="scss">
  .van-notice-bar {
    font-size: 12px;
    height: 24px;
    width: 100%;
    z-index: 20;
  }
  .container {
    background: linear-gradient(to left, #303144, #44495d);
    overflow: scroll;
    height: 100vh;
    margin-bottom: 0 !important;

    .card {
      background: #FFF;
      margin: 20px;
      border-radius: 8px;
      overflow: hidden;
      padding: 0 20px;
    }
  }
  .ad-picture {
    width: 100%;
    &:last-of-type {
      margin-bottom: 60px;
    }
  }
</style>
