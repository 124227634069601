<template>
  <div>
    <van-grid class="custom-grid" direction="horizontal" :column-num="3" clickable>
      <van-grid-item v-for="grid of grids" @click="toggleGrid(grid.key)" :key="grid.key"
                     :class="activeGrid === grid.key ? 'active-grid' : ''" :text=grid.label />
    </van-grid>
    <div class="container">
      <Exp v-if="activeGrid === 'exp'"></Exp>
      <Catastrophe v-else-if="activeGrid === 'catastrophe'"></Catastrophe>
      <Gem v-else-if="activeGrid === 'gem'"></Gem>
      <Equip v-else-if="activeGrid === 'equip'"></Equip>
      <Partner v-else-if="activeGrid === 'partner'"></Partner>
      <Soaring v-else-if="activeGrid === 'soaring'"></Soaring>
    </div>
  </div>
</template>

<script>
  import { Grid, GridItem } from 'vant';
  import Exp from './exp';
  import Catastrophe from './catastrophe';
  import Gem from './gem';
  import Equip from './equip';
  import Partner from './patner';
  import Soaring from './soaring';

  export default {
    name: 'Toolkit',
    components: {
      VanGrid: Grid,
      VanGridItem: GridItem,
      Exp,
      Catastrophe,
      Gem,
      Equip,
      Partner,
      Soaring,
    },
    data() {
      return {
        grids: [
          { key: 'exp', label: '经验计算' },
          { key: 'catastrophe', label: '渡劫消耗' },
          { key: 'soaring', label: '飞升' },
          { key: 'partner', label: '伙伴升星' },
          { key: 'gem', label: '宝石系统' },
          { key: 'equip', label: '神装系统' },
        ],
        activeGrid: 'exp',
      }
    },
    methods: {
      toggleGrid(grid) {
        this.activeGrid = grid;
      }
    }
  }
</script>

<style scoped lang="scss">
  .custom-grid {
    margin-top: -1px;
  }
  .active-grid::v-deep .van-grid-item__content {
    background: #e8b77e;
  }
  .active-grid::v-deep .van-grid-item__text {
    color: #FFF;
  }
  .container {
    margin-top: 20px;
  }
</style>
