import axios from '../utils/request';

// 创建订单
export async function createOrder(data) {
  return axios({
    url: '/api/orders',
    method: 'post',
    data: data,
  });
}

// 估计
export async function evaluation(sn, data) {
  return axios({
    url: `/api/orders/${sn}/evaluation`,
    method: 'patch',
    data: data,
  })
}

// 获取订单信息
export async function getOrderInfo(params) {
  return axios({
    url: '/api/orders',
    method: 'get',
    params: params,
  })
}

// 获取排行榜
export async function getRecordRank(params) {
  return axios({
    url: '/api/record/rank',
    method: 'get',
    params: params,
  })
}

// 账号列表
export async function getAccountList(params) {
  return axios({
    url: '/api/account/list',
    method: 'post',
    data: params,
  })
}

// 账号详情
export async function getAccountInfo(id) {
  return axios({
    url: `/api/account/${id}/info`,
    method: 'get',
  })
}

// 获取广告
export async function getSettings(codes) {
  return axios({
    url: `/api/settings`,
    method: 'get',
    params: { codes },
  })
}

// 获取攻略列表
export async function getArticles(params) {
  return axios({
    url: `/api/article/list`,
    method: 'get',
    params,
  })
}

// 获取攻略详情
export async function geArticleDetail(id) {
  return axios({
    url: `/api/article/${id}/detail`,
    method: 'get',
  })
}

// 获取所有账号标签
export async function getAccountTags() {
  return axios({
    url: `/api/account-tags`,
    method: 'get',
  });
}

// 生成打赏订单
export async function createRewardOrder(data) {
  return axios({
    url: `/api/reward/orders`,
    method: 'post',
    data
  })
}

// 获取打赏订单信息
export async function getRewardOrderInfo(sn) {
  return axios({
    url: `/api/reward/orders`,
    method: 'get',
    params: { sn }
  })
}

// 更新打赏信息
export async function updateRewardOrderInfo(sn, data) {
  return axios({
    url: `/api/reward/orders/${sn}`,
    method: 'patch',
    data
  })
}

export async function getRewardRanks(articleId) {
  return axios({
    url: `/api/reward/list`,
    method: 'get',
    params: { articleId }
  })
}

// 获取搜索次
export async function getSearchWord() {
  return axios({
    url: `/api/search-word/list`,
    method: 'get',
  })
}

// 获取所有金伙伴
export async function getAllPartners() {
  return axios({
    url: `/api/all-partners`,
    method: 'get',
  })
}
