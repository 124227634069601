<template>
  <div>
    <van-form class="custom-form" @submit="onQuery">
      <p class="header">{{ types[activeType-1].tips }}</p>
      <van-field name="radioGroup" label="类型">
        <template #input>
          <van-radio-group v-model="activeType" direction="horizontal">
            <van-radio v-for="type of types" :key="type.label" :name="type.type">{{ type.label }}</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-model="currentLevel"
        type="number"
        name="当前等级"
        label="当前等级"
        placeholder="当前等级"
        :rules="currentLevelRule"
      />
      <van-field
        v-model="targetLevel"
        type="number"
        name="目标等级"
        label="目标等级"
        placeholder="目标等级"
        :rules="targetLevelRule"
      />
      <template v-if="result.count">
        <p class="result">升级所需数量	：<span class="height-light">{{ result.count }}</span></p>
        <p class="result">升级所需仙玉：<span class="height-light">{{ result.jade }}</span></p>
      </template>
      <div class="custom-btn-wrap">
        <van-button round block type="info" native-type="submit">查询</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
  import { Form, Field, Button, RadioGroup, Radio } from 'vant';

  const TYPES = [
    {
      type: 1,
      label: '化境',
      maxLevel: 9,
      tips: '一共九重，目前不计算臻于化境',
    },
    {
      type: 2,
      label: '秘技',
      maxLevel: 40,
      tips: '每个40级，建议全部升到40，开启新飞升',
    },
    {
      type: 3,
      label: '二十八曜',
      maxLevel: 28,
      tips: '一共28级，建议升到28级后,开启新飞升',
    },
    {
      type: 4,
      label: '天赋',
      maxLevel: 40,
      tips: '每个40级，建议全部升到40，开启新飞升',
    },
  ];
  const DATA_ARRAY = [[[125,12500],[380,38000],[835,83500],[1410,141000],[2110,211000],[3010,301000],[4070,407000],[5200,520000]],[[2,400],[2,400],[3,600],[4,800],[5,1000],[6,1200],[7,1400],[8,1600],[9,1800],[10,2000],[11,2200],[12,2400],[12,2400],[13,2600],[15,3000],[16,3200],[17,3400],[19,3800],[20,4000],[21,4200],[23,4600],[25,5000],[27,5400],[28,5600],[29,5800],[31,6200],[33,6600],[35,7000],[37,7400],[39,7800],[41,8200],[43,8600],[45,9000],[48,9600],[51,10200],[53,10600],[55,11000],[57,11400],[60,12000]],[[50,10000],[70,14000],[90,18000],[120,24000],[140,28000],[150,30000],[170,34000],[200,40000],[242,48400],[276,55200],[313,62600],[365,73000],[406,81200],[450,90000],[495,99000],[543,108600],[611,122200],[663,132600],[718,143600],[796,159200],[855,171000],[917,183400],[980,196000],[1046,209200],[1140,228000],[1210,242000],[1283,256600]],[[3,3000],[4,4000],[5,5000],[6,6000],[6,6000],[7,7000],[9,9000],[10,10000],[10,10000],[11,11000],[12,12000],[13,13000],[14,14000],[15,15000],[17,17000],[18,18000],[19,19000],[20,20000],[21,21000],[22,22000],[22,22000],[23,23000],[25,25000],[27,27000],[29,29000],[31,31000],[33,33000],[35,35000],[37,37000],[39,39000],[41,41000],[43,43000],[45,45000],[47,47000],[49,49000],[51,51000],[53,53000],[55,55000],[57,57000]]];

  export default {
    name: 'Soaring',
    components: {
      VanForm: Form,
      VanField: Field,
      VanButton: Button,
      VanRadioGroup: RadioGroup,
      VanRadio: Radio,
    },
    data() {
      return {
        types: TYPES,
        activeType: 1,
        currentLevel: '',
        targetLevel: '',
        result: {
          count: 0,
          jade: 0,
        }
      }
    },
    computed: {
      currentLevelRule() {
        const type = TYPES[this.activeType - 1];
        return [
          { required: true, message: '请填写当前等级' },
          { validator: (value) => value >= 1 && value < type.maxLevel, message: `当前等级必须大于等于1并且小于${type.maxLevel}` }
        ];
      },
      targetLevelRule() {
        const type = TYPES[this.activeType - 1];
        return [
          { required: true, message: '请填写目标等级' },
          { validator: (value) => value > 1 && value <= type.maxLevel, message: `目标等级必须大于1并且小于等于${type.maxLevel}` },
          { validator: (value) => Number(value) > Number(this.currentLevel), message: '目标等级必须大于当前等级' },
        ]
      }
    },
    methods: {
      onQuery() {
        let startIndex = this.currentLevel - 1;
        let endIndex = this.targetLevel - 1;
        let dataArr = DATA_ARRAY[this.activeType - 1];
        let count = 0;
        let jade = 0;
        for (let i = startIndex; i < endIndex; i++) {
          count += dataArr[i][0];
          jade += dataArr[i][1];
        }
        Object.assign(this.result, {
          count,
          jade
        });
      },
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep .van-radio {
    margin-bottom: 10px;
  }
  .header {
    margin: 10px;
    font-size: 14px;
    color: rgb(69, 90, 100, 0.6);
    text-align: left;
  }
  .result {
    margin: 10px;
    font-size: 14px;
    color: #333;
    .height-light {
      color: #FF5260;
    }
  }
  .custom-form {
    margin-bottom: 30px;
  }
  .custom-btn-wrap {
    margin: 16px;
  }
  ::v-deep .van-cell__title {
    width: 60px;
  }
</style>
