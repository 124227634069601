import axios from 'axios';
import { Toast } from 'vant';

axios.defaults.timeout = 30000;
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
axios.defaults.baseURL = '/';

//code状态码200判断
axios.interceptors.response.use(
  response => {
    let data = response.data || {};
    if (!data.code) {
      return data;
    }
    if (data.message) {
      data.msg = data.message;
    }
    if (data.code !== 200) {
      console.error(data.code, data.msg);
      Toast.fail(data.msg || '未知错误...');
      return Promise.reject(Object.assign(new Error(data.msg), data));
    }
    return data.res;
  },
  err => {
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = '请求错误';
          break;
        case 401:
          err.message = '未授权，请登录';
          break;
        case 403:
          err.message = '拒绝访问';
          break;
        case 404:
          err.message = `请求地址出错: ${err.response.config.url}`;
          break;
        case 408:
          err.message = '请求超时';
          break;
        case 500:
          err.message = '服务器内部错误';
          break;
        case 501:
          err.message = '服务未实现';
          break;
        case 502:
          err.message = '网关错误';
          break;
        case 503:
          err.message = '服务不可用';
          break;
        case 504:
          err.message = '网关超时';
          break;
        case 505:
          err.message = 'HTTP版本不受支持';
          break;
        default:
      }
    }
    if (err.message !== 'Network Error') {
      Toast.fail(err.message || '网络异常！');
    }
    return Promise.reject(err);
  }
);

export default axios;
