<template>
  <div>
    <van-form class="custom-form" @submit="onQuery">
      <van-field
        v-model="currentLevel"
        type="number"
        name="起始等级"
        label="起始等级"
        placeholder="起始等级"
        :rules="currentLevelRule"
      />
      <van-field
        v-model="targetLevel"
        type="number"
        name="目标等级"
        label="目标等级"
        placeholder="目标等级"
        :rules="targetLevelRule"
      />
      <template v-if="result.jade">
        <p class="result">仙玉消耗	：<span class="height-light">{{result.jade}}万</span></p>
        <p class="result">增加冰冻：<span class="height-light">{{result.frozen}}</span></p>
      </template>
      <div class="custom-btn-wrap">
        <van-button round block type="info" native-type="submit">查询</van-button>
      </div>

      <van-cell-group inset>
        <van-cell title="小常识：冰冻劫详情" />
        <van-cell v-for="level of frozenLevel" :key="level.index" :title="level.index" :value="level.frozen" />
      </van-cell-group>
    </van-form>
  </div>
</template>

<script>
  import { Form, Field, Button, Cell, CellGroup } from 'vant';

  const DATA_ARRAY = [{"jade":15,"frozen":0},{"jade":22.5,"frozen":0},{"jade":25,"frozen":0},{"jade":25,"frozen":0},{"jade":25,"frozen":0},{"jade":25,"frozen":4000},{"jade":25,"frozen":0},{"jade":25,"frozen":0},{"jade":26.25,"frozen":0},{"jade":27.5,"frozen":0},{"jade":27.5,"frozen":0},{"jade":27.5,"frozen":0},{"jade":27.5,"frozen":0},{"jade":27.5,"frozen":0},{"jade":27.5,"frozen":4400},{"jade":27.5,"frozen":0},{"jade":27.5,"frozen":0},{"jade":28.75,"frozen":0},{"jade":30,"frozen":0},{"jade":30,"frozen":0},{"jade":30,"frozen":0},{"jade":30,"frozen":0},{"jade":30,"frozen":0},{"jade":30,"frozen":4800},{"jade":30,"frozen":0},{"jade":30,"frozen":0},{"jade":31.25,"frozen":0},{"jade":32.5,"frozen":0},{"jade":32.5,"frozen":0},{"jade":32.5,"frozen":0},{"jade":32.5,"frozen":0},{"jade":32.5,"frozen":0},{"jade":32.5,"frozen":4550},{"jade":32.5,"frozen":0},{"jade":32.5,"frozen":0},{"jade":33.75,"frozen":0},{"jade":35,"frozen":0},{"jade":35,"frozen":0},{"jade":35,"frozen":0},{"jade":35,"frozen":0},{"jade":35,"frozen":0},{"jade":35,"frozen":4900},{"jade":35,"frozen":0},{"jade":35,"frozen":0},{"jade":37.5,"frozen":0},{"jade":40,"frozen":0},{"jade":40,"frozen":0},{"jade":40,"frozen":0},{"jade":40,"frozen":0},{"jade":40,"frozen":0},{"jade":40,"frozen":5600},{"jade":40,"frozen":0},{"jade":40,"frozen":0},{"jade":42.5,"frozen":0},{"jade":45,"frozen":0},{"jade":45,"frozen":0},{"jade":45,"frozen":0},{"jade":45,"frozen":0},{"jade":45,"frozen":0},{"jade":45,"frozen":6300},{"jade":45,"frozen":0},{"jade":45,"frozen":0},{"jade":47.5,"frozen":0},{"jade":50,"frozen":0},{"jade":50,"frozen":0},{"jade":50,"frozen":0},{"jade":50,"frozen":0},{"jade":50,"frozen":0},{"jade":50,"frozen":6000},{"jade":50,"frozen":0},{"jade":50,"frozen":0},{"jade":52.5,"frozen":0},{"jade":55,"frozen":0},{"jade":55,"frozen":0},{"jade":55,"frozen":0},{"jade":55,"frozen":0},{"jade":55,"frozen":0},{"jade":55,"frozen":6600},{"jade":55,"frozen":0},{"jade":55,"frozen":0}];

  export default {
    name: 'Catastrophe',
    components: {
      VanForm: Form,
      VanField: Field,
      VanButton: Button,
      VanCell: Cell,
      VanCellGroup: CellGroup,
    },
    data() {
      return {
        currentLevel: '',
        targetLevel: '',
        result: {
          jade: 0,
          frozen: 0,
        },
        currentLevelRule: [
          { required: true, message: '请填写起始等级' },
          { validator: (value) => value >= 1 && value < 81, message: '开始等级必须大于等于1并且小于81' },
        ],
        targetLevelRule: [
          { required: true, message: '请填写目标等级' },
          { validator: (value) => value > 1 && value <= 81, message: '目标等级必须大于1并且小于等于81' },
          { validator: (value) => Number(value) > Number(this.currentLevel), message: '目标等级必须大于起始等级' },
        ],
      }
    },
    computed: {
      frozenLevel: () => {
        let total = 0;
        let result = DATA_ARRAY.map((temp, index) => {
          total += temp.frozen;
          return {
            index: index+1,
            frozen: temp.frozen
          }
        }).filter(temp => temp.frozen);
        result.push({
          index: '总计',
          frozen: total,
        });
        return result;
      }
    },
    methods: {
      onQuery() {
        let startIndex = this.currentLevel - 1;
        let endIndex = this.targetLevel - 1;
        let jade = 0;
        let frozen = 0;
        for (let i = startIndex; i < endIndex; i++) {
          jade += DATA_ARRAY[i].jade;
          frozen += DATA_ARRAY[i].frozen;
        }
        this.result.jade = jade;
        this.result.frozen = frozen;
      },
    }
  }
</script>

<style scoped lang="scss">
  .result {
    margin: 10px;
    font-size: 14px;
    color: #333;
    .height-light {
      color: #FF5260;
    }
  }
  .custom-form {
    margin-bottom: 30px;
  }
  .custom-btn-wrap {
    margin: 16px;
  }
  ::v-deep .van-cell-group {
    margin: 20px;
  }
</style>
