<template>
  <div>
    <van-overlay :show="showTips" @click="showTips = false">
      <div class="wrapper">
        <img src="./../assets/slide-icon.svg">
        往左滑，快速查看最新数据
      </div>
    </van-overlay>
    <div class="save-btn" @click="showImg">点击保存</div>
    <swiper class="custom-swiper" ref="mySwiper" :options="swiperOptions">
      <swiper-slide class="card-wrap" v-for="(account, index) in virtualSlides" :key="index">
        <account-intro-card :showIntroImg="showImgAccountId === account.id" :isQuickView="1" :key="account.id"
                            @update:showIntroImg="showImgAccountId = 0" :account-info="account"></account-intro-card>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
  import { Overlay } from 'vant';
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
  import 'swiper/css/swiper.css';

  import AccountIntroCard from '../components/account-intro-card';
  import { getAccountList } from './../api/index';

  export default {
    name: 'QuickAccountList',
    components: {
      Swiper,
      SwiperSlide,
      AccountIntroCard,
      VanOverlay: Overlay,
    },
    data() {
      // 是否查看过提示
      let visibleQuickTips = localStorage.getItem('hadSeenQuickTips');
      let showTips = !visibleQuickTips;
      localStorage.setItem('hadSeenQuickTips', '1');
      setInterval(() => this.showTips = false, 5000);
      let that = this;

      return {
        showTips: showTips,
        showImgAccountId: 0, // 要展示图片的账号id
        isActive: true,
        limit: 6,
        skip: 0,
        finished: false,
        accounts: [],
        virtualSlides: [],
        swiperOptions: {
          autoHeight: true,
          width: window.innerWidth - 20,
          spaceBetween : 10,
          slidesOffsetBefore: 10,
          slidesOffsetAfter: -10,
          resistanceRatio: 0,
          virtual: {
            renderExternal: data => {
              this.$set(this, 'virtualSlides', data.slides);
              if (this.swiper) {
                this.swiper.$wrapperEl.children('.swiper-slide').css('left', `${data.offset}px`);
              }
            },
          },
          on: {
            slideChangeTransitionStart: function() {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            },
            slideChangeTransitionEnd: function() {
              // 虚拟slides只能自己调整高度，swiper bug
              let height = 0;
              if (this.slides.length === 2) {
                if (this.activeIndex === 0) {  // 第一个
                  height = this.slides[0].offsetHeight;
                } else {  // 最后一个
                  height = this.slides[1].offsetHeight;
                }
              } else {
                height = this.slides[1].offsetHeight;
              }
              if (height) {
                this.$wrapperEl.css('height', `${height}px`);
              }
            },
            slideChange: function () {
              if (that.accounts.length - this.activeIndex <= 3) {
                that.getAccounts();
              }
            },
          },
        },
        images: [],
        isLoading: false,
        loadingAccount: null,
        loadingIndex: -1,
        swiper: null,
      }
    },
    updated() {
      this.swiper = this.$refs.mySwiper.$swiper;
    },
    created() {
      this.initData();
    },
    // 渲染图片过程中，切离会报错，回来后需要重新渲染
    deactivated() {
      if (this.loadingIndex !== this.accounts.length) {
        this.isActive = false;
      }
    },
    activated() {
      this.isActive = true;
    },
    methods: {
      initData() {
        this.skip = 0;
        this.finished = false;
        this.accounts = [];
        this.loading = true;
        this.getAccounts();
      },
      async getAccounts() {
        if (this.finished) return false;
        try {
          const accounts = await getAccountList({
            orderBy: 'id',
            skip: this.skip,
            limit: this.limit,
          });

          this.skip += accounts.length;
          for (let i = 0; i < accounts.length; i++) {
            this.images.push('');
          }
          this.swiper.virtual.appendSlide(accounts);
          this.accounts.push(...(accounts || []));
          if (!accounts || !accounts.length) {
            this.finished = true;
          }
        } catch {
          this.finished = true;
        } finally {
          this.loading = false;
        }
      },
      showImg() {
        this.showImgAccountId = this.accounts[this.swiper.activeIndex].id;
      }
    }
  }
</script>

<style scoped lang="scss">
  .van-overlay {
    z-index: 1000;

    .wrapper {
      color: #FFF;
      position: relative;
      top: 50px;
      text-align: center;
      font-size: 20px;
      img {
        width: 170px;
        display: block;
        margin: 50% auto 20px;
      }
    }
  }
  .img-load {
    position: absolute;
    left: -100vw;
    top: -100vh;
  }
  .custom-swiper {
    margin-top: 20px;
  }
  .card-wrap {
    border-radius: 20px;
    overflow: hidden;
    &.swiper-slide {
    }

  }
  .van-loading {
    margin-top: 230px;
  }
  .save-btn {
    position: fixed;
    z-index: 100;
    right: 0;
    top: 0;
    background: rgb(255 156 41);
    color: #FFF;
    padding: 10px;
    border-radius: 0 0 0 15px;
    font-size: 16px;
    cursor: pointer;
  }
</style>
