<template>
  <div class="article-container" v-if="article">
    <h1 class="article-title">{{ article.title }}</h1>
    <div class="article-content" v-html="article.content"></div>
    <!--<reward @freshRank="freshRank" :article-id="id"></reward>-->
    <!--<reward-rank-list v-if="!isRefreshing" :article-id="id"></reward-rank-list>-->
  </div>
</template>

<script>
  import { Toast } from 'vant';
  import { geArticleDetail } from './../api/index';
  // import Reward from "../components/reward";
  // import RewardRankList from "../components/reward-rank-list";

  export default {
    name: 'ArticleDetail',
    // components: {RewardRankList, Reward},
    props: [ 'id' ],
    data() {
      return {
        article: null,
        isRefreshing: false,
      }
    },
    async created() {
      const toast = Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      try {
        const article = await geArticleDetail(this.id);
        if (!article) Toast.fail('攻略不存在');
        this.article = article;
      } catch (e) {
        Toast.fail('加载失败');
      } finally {
        toast.clear();
      }
    },
    methods: {
      freshRank() {
        this.isRefreshing = true;
        this.$nextTick(() => this.isRefreshing = false);
      }
    }
  }
</script>

<style scoped lang="scss">
  .article-container {
    background: #FFF;
    padding: 0 20px;
    overflow: hidden;
    margin-bottom: 0 !important;
    .article-title {
      font-size: 20px;
      padding: 16px 0;
      margin: 0;
    }
    .article-content {
      overflow: hidden;
    }
  }
  .rank-container {
    width: 100%;
    box-shadow: 1px 1px 3px 0 #ababab9c;
    background: #ececec;
  }
</style>
